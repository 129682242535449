import { windowGlobal } from '../utils/window-global'

export function formatTimestamp(timestamp, language) {
  const options = {
    weekday: 'short',
    month: 'short',
    year: '2-digit',
    day: '2-digit'
  };
  const date = new Date(timestamp);
  return date.toLocaleDateString(language, options).replace(',', '');
}
export function getLocale() {
  return (windowGlobal?.navigator?.languages && windowGlobal.navigator.languages?.length) ? windowGlobal.navigator.languages[0] : (windowGlobal?.navigator?.language ?? 'en-US');
}
function getLocaleFirstDayOfWeek() {
  if (windowGlobal?.Intl) {
    const locale = new Intl.Locale(getLocale())
    if ('getWeekInfo' in locale) {
      const intlFirstDay = locale.getWeekInfo().firstDay
      if (intlFirstDay === 7) { // Intl defines "Sunday" as 7, however Date.getDay() returns "Sunday" as 0, thus the conversion
        return 0
      }
      return intlFirstDay
    }
  }
  return 0 // if weekInfo is unavailable, just return default value (which is Sunday)
}
const localeFirstDayOfWeek = new Date().getDay() + getLocaleFirstDayOfWeek() // Get the day of the week (0-6)
export function getWeekStart() {
  const currentDate = new Date()
  const diff = currentDate.getDate() - localeFirstDayOfWeek // Adjust the date to get the start of the week
  return new Date(currentDate.setDate(diff))
}
export function getNextWeekStart() {
  const currentDate = new Date()
  const diff = currentDate.getDate() - localeFirstDayOfWeek + 7 // Adjust the date to get the start of the week
  return new Date(currentDate.setDate(diff))
}