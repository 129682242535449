import * as React from 'react';
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { globalHistory } from '@reach/router'

import { Layout } from '../../layouts';
import { JobsItems } from './components/job-items';
import { Job } from './components/job';
import { JobsHeader } from './components/jobs-header';
import { JobsFilters } from './components/jobs-filters';
import { reducer, StateContext, StateDispatchContext, getGroupedJobs, getLimitedGroupedJobs } from './state'
import { globalSessionStorage } from '../../utils/session-storage'
import { DownloadSpAppPopup } from '../../components/common/download-sp-app-popup'
import './index.sass';

const SESSION_JOB_PAGE_STATE = 'SESSION_JOB_PAGE_STATE';

const JobTemplate = ({
  pageContext: {
    markets,
    addresses,
    professionList,
    jobs,
    index,
    country,
    language,
    initialProfessionId,
    initialAddress,
  },
}) => {
  const [isDownloadPopupVisible, setDownloadPopupVisible] = React.useState(false);
  const [state, dispatch] = React.useReducer(reducer, initialProfessionId, () => {
    const storageState = globalSessionStorage.getItem(SESSION_JOB_PAGE_STATE);
    if (storageState !== null) {
      const parsedState = JSON.parse(storageState);
      return {
        ...parsedState,
        selectedProfessionIds: new Set(parsedState.selectedProfessionIds),
        professionMap: new Map(parsedState.professionMap),
      };
    }
    const professionMap = new Map();
    professionList.forEach(item => { professionMap.set(item.id, item); });
    let filteredJobs = jobs;
    if (initialAddress !== null) {
      filteredJobs = filteredJobs.filter(item => item.address.toLowerCase() === initialAddress.toLowerCase());
    }
    if (initialProfessionId !== null) {
      const professionName = Array.from(professionMap.values()).find(item => item.id === initialProfessionId).name;
      filteredJobs = filteredJobs.filter(item => item.work_category_name.toLowerCase() === professionName.toLowerCase());
    }
    const groupedFilteredJobs = getGroupedJobs(filteredJobs)
    return {
      selectedProfessionIds: initialProfessionId === null ? new Set() : new Set([initialProfessionId]),
      visibleJobs: getLimitedGroupedJobs(groupedFilteredJobs, 10),
      filteredJobs: groupedFilteredJobs,
      selectedAddress: initialAddress ?? null,
      scrollPosition: null,
      professionMap,
      jobs,
    };
  });
  React.useEffect(() => {
    globalSessionStorage.setItem(SESSION_JOB_PAGE_STATE, JSON.stringify({
      ...state,
      selectedProfessionIds: [...state.selectedProfessionIds], // Set cannot be serialized
      professionMap: [...state.professionMap],
    }));
  }, [state]);
  React.useEffect(() => {
    return globalHistory.listen(({ location }) => {
      if (!location.pathname.includes('/jobs')) {
        globalSessionStorage.removeItem(SESSION_JOB_PAGE_STATE);
      }
    });
  }, []);
  const { t } = useTranslation();

  const { visibleJobs } = state
  const selectedMarket = React.useMemo(() => markets.find(item => item.code === country), []);
  const { dateKey, selectedIndex } = React.useMemo(() => { // returns dateKey and job index inside that dateKey
    if (index === null || (initialProfessionId === null && initialAddress === null)) return {
      dateKey: null,
      selectedIndex: null,
    };
    const selectedJob = jobs[index];
    let indexOfVisibleJob = null;
    const dateKey = Object.keys(visibleJobs).find(date => visibleJobs[date].find((job, index) => {
      if (job.id === selectedJob.id) {
        indexOfVisibleJob = index;
        return true;
      }
      return false;
    }));
    return { dateKey, selectedIndex: indexOfVisibleJob };
  }, [index, visibleJobs]);

  const visibleJob = selectedIndex === null ? Object.values(visibleJobs)[0]?.[0] : visibleJobs[dateKey][selectedIndex]

  return (
    <StateContext.Provider value={state}>
      <StateDispatchContext.Provider value={dispatch}>
        <Layout
          pageTitle={t(`${country}.jobs.title`)}
          askForMarket={false}
          country={country}
          language={language}
        >
          <div className="jobs-page">
            <JobsHeader className="jobs-page__header-container jobs-page__inner-container" />
            <JobsFilters
              country={country}
              className={`jobs-page__inner-container jobs-page__filters-container ${index !== null ? 'jobs-page__details_mobile-hidden' : ''}`}
              markets={markets}
              selectedMarket={selectedMarket}
              onMarketSelected={market => {
                globalSessionStorage.removeItem(SESSION_JOB_PAGE_STATE);
                navigate(`/${market.code}/${language}/jobs`);
              }}
              onAddressSelected={address => {
                navigate(`/${country}/${language}/jobs/${address}`);
              }}
              addressOptions={addresses}
            />
            <div className={`jobs-page__wrapper jobs-page__inner-container ${selectedIndex !== null ? 'jobs-page_mobile-no-paddings' : ''}`}>
              <JobsItems
                country={country}
                language={language}
                selectedItem={visibleJob}
                isSelectedManually={index !== null}
              />
              {visibleJob && (
                <main className={`jobs-page__details ${index === null ? 'jobs-page__details_mobile-hidden' : ''}`}>
                  <Job
                    job={visibleJob}
                    country={country}
                    language={language}
                    onGetJobPress={() => {
                      setDownloadPopupVisible(true)
                    }}
                  />
                </main>
              )}
            </div>
            <DownloadSpAppPopup isVisible={isDownloadPopupVisible} setVisible={setDownloadPopupVisible} />
          </div>
        </Layout>
      </StateDispatchContext.Provider>
    </StateContext.Provider>
  )
}

export default JobTemplate
