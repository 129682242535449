import { createContext } from 'react';
import { windowGlobal } from '../../utils/window-global';

export const actions = {
  APPLY_CATEGORIES_FILTER: 'APPLY_CATEGORIES_FILTER',
  RESET_CATEGORIES_FILTER: 'RESET_CATEGORIES_FILTER',
  SET_ADDRESS_FILTER: 'SET_ADDRESS_FILTER',
  REMOVE_ITEM_FROM_CATEGORY_FILTER: 'REMOVE_ITEM_FROM_CATEGORY_FILTER',
  INCREASE_VISIBLE_JOBS: 'INCREASE_VISIBLE_JOBS',
  SET_SCROLL_POSITION: 'SET_SCROLL_POSITION',
};

export function getGroupedJobs(jobs) {
  return jobs.reduce((acc, job) => {
    const originDateWithLocaleShift = new Date(job.start_time * 1000)
    const date = new Date(Date.UTC(originDateWithLocaleShift.getUTCFullYear(), originDateWithLocaleShift.getUTCMonth(), originDateWithLocaleShift.getUTCDate()))
    const firstDayOfWeek = new Date(date.setDate(date.getDate() - date.getDay()))
    const dateKey = firstDayOfWeek.toISOString().split('T')[0]
    acc[dateKey] = acc[dateKey] || []
    acc[dateKey].push(job)
    return acc
  }, {})
}
export function getLimitedGroupedJobs(groupedJobs, maxJobs) {
  const visibleGroupedJobs = {}
  let visibleJobs = 0
  for (const dateKey in groupedJobs) {
    if (visibleJobs >= maxJobs) {
      break
    }
    visibleGroupedJobs[dateKey] = []
    for (const job of groupedJobs[dateKey]) {
      if (visibleJobs >= maxJobs) {
        break
      }
      visibleGroupedJobs[dateKey].push(job)
      visibleJobs++
    }
  }
  return visibleGroupedJobs
}

export function reducer(state, action) {
  switch (action.type) {
    case actions.INCREASE_VISIBLE_JOBS: {
      const currentlyVisibleJobsCount = Object.values(state.visibleJobs).map(value => value.length).reduce((a, b) => a + b, 0)
      const newVisibleJobs = getLimitedGroupedJobs(state.filteredJobs, currentlyVisibleJobsCount + 10)
      return { ...state, visibleJobs: newVisibleJobs };
    }
    case actions.APPLY_CATEGORIES_FILTER: {
      let filteredJobs = state.filteredJobs;
      let visibleJobs = state.visibleJobs;
      if (action.ids.size > 0) {
        const professionsNames = new Set(Array.from(action.ids).map(id => state.professionMap.get(id).name));
        let matchingJobs = state.jobs.filter(job => {
          return professionsNames.has(job.work_category_name);
        });
        if (state.selectedAddress !== null) {
          matchingJobs = matchingJobs.filter(job => job.address.toLowerCase() === state.selectedAddress.toLowerCase());
        }
        const groupedJobs = getGroupedJobs(matchingJobs);
        filteredJobs = groupedJobs;
        visibleJobs = getLimitedGroupedJobs(groupedJobs, 10);
      }
      windowGlobal.scrollTo({ top: 0, behavior: 'smooth' });
      return {
        ...state,
        selectedProfessionIds: action.ids,
        filteredJobs,
        visibleJobs,
        scrollPosition: 0,
      };
    }
    case actions.RESET_CATEGORIES_FILTER: {
      if (state.selectedAddress !== null) {
        const matchingJobs = state.jobs.filter(job => job.address.toLowerCase() === state.selectedAddress.toLowerCase());
        const groupedJobs = getGroupedJobs(matchingJobs);
        const filteredJobs = groupedJobs;
        const visibleJobs = getLimitedGroupedJobs(groupedJobs, 10);
        windowGlobal.scrollTo({ top: 0, behavior: 'smooth' });
        return {
          ...state,
          selectedProfessionIds: new Set(),
          filteredJobs,
          visibleJobs,
          scrollPosition: 0,
        };
      }
      const groupedJobs = getGroupedJobs(state.jobs);
      const filteredJobs = groupedJobs;
      const visibleJobs = getLimitedGroupedJobs(groupedJobs, 10);
      windowGlobal.scrollTo({ top: 0, behavior: 'smooth' });
      return {
        ...state,
        selectedProfessionIds: new Set(),
        filteredJobs,
        visibleJobs,
        scrollPosition: 0,
      };
    }
    case actions.REMOVE_ITEM_FROM_CATEGORY_FILTER: {
      const newItems = new Set(state.selectedProfessionIds);
      newItems.delete(action.id);
      if (newItems.size > 0) {
        const professionsNames = new Set(Array.from(newItems).map(id => state.professionMap.get(id).name));
        let matchingJobs = state.jobs.filter(job => {
          return professionsNames.has(job.work_category_name);
        });
        if (state.selectedAddress !== null) {
          matchingJobs = matchingJobs.filter(job => job.address.toLowerCase() === state.selectedAddress.toLowerCase());
          const groupedJobs = getGroupedJobs(matchingJobs);
          const filteredJobs = groupedJobs;
          const visibleJobs = getLimitedGroupedJobs(groupedJobs, 10);
          return { ...state, selectedProfessionIds: newItems, filteredJobs, visibleJobs, scrollPosition: 0 };
        }
        const groupedJobs = getGroupedJobs(matchingJobs);
        const filteredJobs = groupedJobs;
        const visibleJobs = getLimitedGroupedJobs(groupedJobs, 10);
        windowGlobal.scrollTo({ top: 0, behavior: 'smooth' });
        return { ...state, selectedProfessionIds: newItems, filteredJobs, visibleJobs, scrollPosition: 0 };
      } else {
        let matchingJobs = state.jobs
        if (state.selectedAddress !== null) {
          matchingJobs = matchingJobs.filter(job => job.address.toLowerCase() === state.selectedAddress.toLowerCase());
        }
        const groupedJobs = getGroupedJobs(matchingJobs);
        const filteredJobs = groupedJobs;
        const visibleJobs = getLimitedGroupedJobs(groupedJobs, 10);
        windowGlobal.scrollTo({ top: 0, behavior: 'smooth' });
        return { ...state, selectedProfessionIds: newItems, filteredJobs, visibleJobs, scrollPosition: 0 };
      }
    }
    case actions.SET_ADDRESS_FILTER: {
      let matchingJobs = state.jobs.filter(job => job.address === action.address);
      if (state.selectedProfessionIds.size > 0) {
        const professionsNames = new Set(Array.from(state.selectedProfessionIds).map(id => state.professionMap.get(id).name));
        matchingJobs = matchingJobs.filter(job => {
          return professionsNames.has(job.work_category_name);
        });
      }
      const groupedJobs = getGroupedJobs(matchingJobs);
      const filteredJobs = groupedJobs;
      const visibleJobs = getLimitedGroupedJobs(groupedJobs, 10);
      windowGlobal.scrollTo({ top: 0, behavior: 'smooth' });
      return { ...state, selectedAddress: action.address, filteredJobs, visibleJobs, scrollPosition: 0 };
    }
    case actions.SET_SCROLL_POSITION: {
      return { ...state, scrollPosition: action.scrollPosition };
    }
  }
  throw new Error(`Unexpected action type: ${action.type}`);
}

export const StateContext = createContext(null);
export const StateDispatchContext = createContext(null);
