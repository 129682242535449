import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { useDebouncedCallback } from 'use-debounce'
import { useTranslation, Trans } from 'react-i18next'

import { getWeekStart, getNextWeekStart, getLocale } from '../../../utils/dates'
import { StateContext, StateDispatchContext, actions, getLimitedGroupedJobs } from '../state'
import './job-items.sass'

const thisWeekYyyyMmDd = getWeekStart().toISOString().split('T')[0]
const nextWeekYyyyMmDd = getNextWeekStart().toISOString().split('T')[0]

export function JobsItems({ isSelectedManually, selectedItem, country, language }) {
  const { filteredJobs, visibleJobs, selectedProfessionIds, scrollPosition, selectedAddress } = React.useContext(StateContext);
  const dispatch = React.useContext(StateDispatchContext);
  const updateVisibleJobs = useDebouncedCallback(
    React.useCallback(() => {
      dispatch({ type: actions.INCREASE_VISIBLE_JOBS })
    }, [selectedProfessionIds]),
    300,
  );
  const setScrollablePosition = useDebouncedCallback(
    React.useCallback(event => {
      dispatch({
        type: actions.SET_SCROLL_POSITION,
        scrollPosition: event.target.scrollingElement.scrollTop,
      });
    }, []),
    200,
  );
  const { t } = useTranslation();
  React.useEffect(() => {
    const initialScroll = scrollPosition;
    // Sets the initial scroll (i.g. when navigating to a job which is not in the beginning of the list)
    if (initialScroll !== null) {
      document.documentElement.scrollTop = parseInt(initialScroll);
    }
    document.addEventListener('scroll', event => {
      // Remembers the scroll position to restore it on future navigation
      setScrollablePosition(event);
      if (Math.abs(event.target.scrollingElement.scrollHeight - event.target.scrollingElement.scrollTop - event.target.scrollingElement.clientHeight) < 100) {
        updateVisibleJobs();
      }
    });
  }, []);

  const getWeekTitle = dateKey => {
    if (thisWeekYyyyMmDd === dateKey) {
      return t(`${country}.jobs.this_week`)
    } else if (nextWeekYyyyMmDd === dateKey) {
      return t(`${country}.jobs.next_week`)
    } else {
      return t(`${country}.jobs.week_of_date`, {
        date: new Date(dateKey).toLocaleDateString(getLocale(), { day: '2-digit', month: 'short' }),
      })
    }
  }

  return (
    <aside className={`job-items ${isSelectedManually ? 'job-items_mobile-hidden' : ''}`}>
      <ul className="job-items__section-list">
        {Object.entries(visibleJobs).map(([date, dateJobs]) => {
          const weekTitle = getWeekTitle(date)
          const weekJobsCount = filteredJobs[date].length
          return (
            <li key={date} className="job-items__section-item">
              <div className="job-items__title">
                <Trans i18nKey={`${country}.jobs.number_of_active_jobs`} count={weekJobsCount} date={weekTitle}>
                  {weekTitle}<div className="job-items__title_highlighted">{{ count: weekJobsCount }} jobs</div>
                </Trans>
              </div>
              <ul className="job-items__list">
                {dateJobs.map(job => (
                  <li key={job.id} className={`job-items__card ${job.id === selectedItem?.id ? 'job-items__card_selected' : ''}`}>
                    <Link
                      to={`/${country}/${language}/jobs/${job.address.toLowerCase()}/${job.work_category_name.toLowerCase()}/${job.id}`}
                      className="job-items__link"
                    >
                      <div className="job-items__card-header">
                        <div className="job-items__img-container">
                          <img
                            src={job.work_category_image}
                            className="job-items__img"
                            alt=""
                          />
                        </div>
                        <div className="job-items__card-title">
                          {job.work_category_name}
                        </div>
                      </div>
                      <div className="job-items__card-main">
                        <div className="job-items__row">
                          <StaticImage
                            src="../../../assets/app/location.png"
                            alt=""
                            className="job-items__location-icon"
                          />
                          <div className="job-items__label">{job.address}</div>
                        </div>
                        <div className="job-items__row job-items__row_multiline">
                          <StaticImage
                            src="../../../assets/app/calendar.png"
                            alt=""
                            className="job-items__calendar-icon"
                          />
                          <div>
                            <div className="job-items__sublabel">{t(`${country}.jobs.start`)}</div>
                            <div className="job-items__label">
                              {new Date(job.start_time * 1000).toISOString().split('T')[0]}
                            </div>
                          </div>
                        </div>
                        <div className="job-items__card-bottom-row">
                          <div className="job-items__row job-items__row_shortened-gap">
                            <StaticImage
                              src="../../../assets/app/person.png"
                              alt=""
                              className="job-items__person-icon"
                            />
                            <div className={`job-items__label ${job.slots.left < 5 ? 'job-items__label_highlited' : ''}`}>
                              {t(`${country}.jobs.number_of_jobs_left`, {
                                count: job.slots.left,
                              })}
                            </div>
                            <div className="job-items__spots-total">
                              {t(`${country}.jobs.number_of_jobs_total`, {
                                count: job.slots.total,
                              })}
                            </div>
                          </div>
                          <div className="job-items__card-corner">
                            <div className="job-items__money">
                              <div className="job-items__income">
                                {/* 
                                if job is of hourly type, we show daily rate in the list
                                and if it's any other type, we show montly rate in the list
                                */}
                                {job.rate_type === 1 ? job.daily_rate : job.monthly_rate}
                              </div>
                              {' '}
                              <div className="job-items__currency">{job.currency}</div>
                            </div>
                            <div className="job-items__money-description">
                              {job.rate_type === 1 ? (
                                t(`${country}.jobs.approximately_per_day`)
                              ) : (
                                t(`${country}.jobs.approximately_per_month`)
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          )
        })}
      </ul>
    </aside>
  );
}
